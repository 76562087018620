
import { defineComponent } from "vue";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

export default defineComponent({
  name: "individual-client-claims-history-table",
  components: {},
  props: {
    widgetClasses: String,
    policySummary: Object
  },
  //   setup() {},
  setup() {
    const list = [
      {
        policyYear: "2021",
        policyType: "House Insurance",
        policyNo: "EB/5007/50/2018/60086/899",
        referenceNo: "HH/HO/08/0028",
        sumInsured: ".00",
        status: "Active",
        startDate: "2021-05-05",
        expiryDate: "2022-05-05"
      },
      {
        policyYear: "2021",
        policyType: "Life Insurance",
        policyNo: "ZL/00327/2013/GL/LA",
        referenceNo: "LIF/HO/08/0005",
        sumInsured: ".00",
        status: "Active",
        startDate: "2021-05-05",
        expiryDate: "2022-05-05"
      },
      {
        policyYear: "2021",
        policyType: "Motor Insurance",
        policyNo: "SAN/CMV/03412/2017/HQ",
        referenceNo: "CM/HO/08/00027",
        sumInsured: ".00",
        status: "Active",
        startDate: "2021-05-05",
        expiryDate: "2022-05-05"
      },
      {
        policyYear: "2021",
        policyType: "Health Insurance",
        policyNo: "HI/01220/2021/GL/ZR",
        referenceNo: "HK/PO/12/6427",
        sumInsured: ".00",
        status: "Active",
        startDate: "2021-05-05",
        expiryDate: "2022-05-05"
      },
      {
        policyYear: "2021",
        policyType: "Motor Insurance",
        policyNo: "861A/N10984A",
        referenceNo: "MTR/HO/10/0004",
        sumInsured: ".00",
        status: "Inactive",
        startDate: "2021-05-05",
        expiryDate: "2022-05-05"
      }
    ];

    return {
      list
    };
  }
});
